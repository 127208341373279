import { useEffect, useMemo, useState } from "react";
import {getActivities} from "../../../routes/api";
import moment from "moment";

export default function Activity() {
    const [isLoading, setIsLoading] = useState(true);
    const [activities, setActivities] = useState([]);


    useEffect(() => {
        getActivities().then((res) => {
            console.log(res)
            setActivities(res?.data?.data?.data);
            setIsLoading(false);
            return true;
        });
    }, []);

    const memoActivities = useMemo(() => activities, [activities]);

    return (
        <div>
            {!isLoading ? (
                <>
                    <table className="table-auto w-full border-collapse mt-4 text-sm">
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Activity</th>
                            <th>Channel ID</th>
                            <th>Play ID</th>
                        </tr>
                        </thead>
                        <tbody>
                            {memoActivities?.map((item, index) => {

                                return (
                                    <tr
                                        className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
                                    >
                                        <td className="overflow-hidden p-2">{moment(item?.created_at).format(
                                            "YYYY-MM-DD hh:mm A"
                                        )}</td>
                                        <td className="overflow-hidden p-2">{item?.user?.name} ({item?.user?.email})</td>
                                        <td className="overflow-hidden p-2">{item?.activity}</td>
                                        <td className="overflow-hidden p-2">{item?.channel_id}</td>
                                        <td className="overflow-hidden p-2">{item?.play_id}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <table className="table-auto rounded w-full border-collapse mt-8 text-sm">
                    <tbody>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
}
