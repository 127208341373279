// useAuthSidebarOptions.js
import { useSelector } from 'react-redux';
import {
  faChartColumn,
  faSignOut,
  faBars,
  faUsers,
  faPlus,
  faClock,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import {setToken, setIsLoggedIn, setIsAdmin} from '../redux/slices/auth'; // Adjust the path as necessary


const useAuthSidebarOptions = () => {
  const isAdmin = useSelector(state => state.auth?.isAdmin); // Adjust according to your state structure
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn); // Adjust according to your state structure

  const authSidebarOptions = [
    {
      icon: faChartColumn,
      title: "Plays",
      url: "plays",
      show: isLoggedIn,
    },
    {
      icon: faFileInvoice,
      title: "Channels",
      url: "channels",
      show: isLoggedIn,
    },
    {
      icon: faUsers,
      title: "Users",
      url: "users",
      action: null,
      show: isAdmin, // Only show if the user is admin
    },
    // {
    //   icon: faPlus,
    //   title: "Request Quote",
    //   url: "add-quote",
    //   action: null,
    // },
    {
      icon: faClock,
      title: "Activity",
      url: "activity",
      action: null,
      show: isAdmin, // Only show if the user is admin
    },
    {
      icon: faSignOut,
      title: "Logout",
      url: "logout",
      action: (dispatch) => {
        localStorage.removeItem("token");
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("isAdmin");
        dispatch(setToken(null));
        dispatch(setIsLoggedIn(false));
        dispatch(setIsAdmin(false));
        window.location.reload();
      },
    },
  ];

  // Filter out options that shouldn't be shown
  return authSidebarOptions.filter(option => option.show !== false);
};

export default useAuthSidebarOptions;
