import "./App.css";
import { useEffect } from "react";
import Authentication from "./components/Authentication";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/login";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import Channels from "./pages/auth-pages/channels/Channels";
import Plays from "./pages/auth-pages/plays/Plays";
import { getCsrf } from "./routes/api";
import EditChannel from "./pages/auth-pages/channels/EditChannel";
import EditPlay from "./pages/auth-pages/plays/EditPlay";
import SingleChannel from "./pages/auth-pages/channels/SingleChannel";
import SinglePlay from "./pages/auth-pages/plays/SinglePlay";
import AddChannel from "./pages/auth-pages/channels/AddChannel";
import AddPlay from "./pages/auth-pages/plays/AddPlay";
import AddQuote from "./pages/auth-pages/quotes/AddQuote";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {setIsAdmin, setIsLoggedIn, setToken} from "./redux/slices/auth";
import Users from "./pages/auth-pages/user/Users";
import AddUser from "./pages/auth-pages/user/AddUser";
import EditUser from "./pages/auth-pages/user/EditUser";
import SingleUser from "./pages/auth-pages/user/SingleUser";
import { setPermissions } from "./redux/slices/permissions";
import { fetchUserPermissions } from "./routes/api";
import Activity from "./pages/auth-pages/activity/Activity"; // Create an API call for this


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const token = localStorage.getItem("token");
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    const abortController = new AbortController();
    getCsrf();
    dispatch(setToken(token));
    dispatch(setIsLoggedIn(isLoggedIn));
    dispatch(setIsAdmin(isAdmin));
    let path =
      location.pathname == "/"
        ? "/auth/dashboard/plays"
        : location.pathname;
    navigate(path, { state: location, replace: true });

    async function getPermissions() {
      const permissions = await fetchUserPermissions();
      dispatch(setPermissions(permissions?.data?.data));
    }

    if (isLoggedIn) {
      getPermissions();
    }

    return () => abortController.abort();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="auth" element={<Authentication />}>
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="dashboard" element={<Plays />} />

            <Route path="users" element={<Users />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-user/:id" element={<EditUser />} />
            <Route path="single-user/:id" element={<SingleUser />} />

            <Route path="channels" element={<Channels />} />
            <Route path="add-channel" element={<AddChannel />} />
            <Route path="edit-channel/:id" element={<EditChannel />} />
            <Route path="single-channel/:id" element={<SingleChannel />} />

            <Route path="plays" element={<Plays />} />
            <Route path="add-play" element={<AddPlay />} />
            <Route path="edit-play/:id" element={<EditPlay />} />
            <Route path="single-play/:id" element={<SinglePlay />} />

            {/*<Route path="quotes" element={<Quotes />} />*/}
            <Route path="add-quote" element={<AddQuote />} />
            {/*<Route path="edit-quote/:id" element={<EditQuote />} />*/}
            {/*<Route path="single-quote/:id" element={<SingleQuote />} />*/}

            <Route path="activity" element={<Activity />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
