import { useEffect, useMemo, useState } from "react";
import { getChannels } from "../../../routes/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/permissions";

export default function Channels() {
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getLastChannels();
  }, []);

  async function getLastChannels(params) {
    return getChannels({}).then((res) => {
      const allChannels = res?.data?.data?.data;

      // Filter channels based on `can_create_play` permission or if user is admin
      const filteredChannels = isAdmin
          ? allChannels
          : allChannels.filter((channel) =>
              hasPermission(permissions, channel.id, "can_view_play")
          );

      setChannels(filteredChannels);
      setIsLoading(false);
      return true;
    });
  }

  const memoChannels = useMemo(() => channels, [channels]);

  const permissions = useSelector((state) => state.permissions);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  return (
      <div className="p-4">
        {!isLoading ? (
            <>
              {isAdmin && (
                  <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <Link
                        to="/auth/dashboard/add-channel"
                        className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add Channel
                    </Link>
                  </div>
              )}

              <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse mt-4 text-sm">
                  <thead>
                  <tr>
                    <th className="px-2 py-1 text-left">Channel ID</th>
                    <th className="px-2 py-1 text-left">Name</th>
                    <th className="px-2 py-1 text-left">Telegram Chat ID</th>
                    <th className="px-2 py-1 text-left">Signal ID</th>
                    <th className="px-2 py-1 text-left">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {memoChannels?.map((item, index) => (
                      <tr
                          key={index}
                          className="overflow-hidden text-center border-b border-t border-gray-300"
                      >
                        <td className="p-2">{item?.id}</td>
                        <td className="p-2">{item?.name}</td>
                        <td className="p-2">{item?.telegram_chat_id}</td>
                        <td className="p-2">{item?.signal_id}</td>
                        <td className="p-2 text-sm">
                          <Link
                              to={`/auth/dashboard/single-channel/${item.id}`}
                              className="inline-flex items-center mr-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                          {hasPermission(permissions, item.id, "can_edit_play", isAdmin) && (
                              <Link
                                  to={`/auth/dashboard/edit-channel/${item.id}`}
                                  className="inline-flex items-center gap-x-1.5 mr-2 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Link>
                          )}
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </>
        ) : (
            <div className="overflow-x-auto">
              <table className="table-auto w-full rounded border-collapse mt-8 text-sm">
                <tbody>
                {Array(5)
                    .fill()
                    .map((_, idx) => (
                        <tr
                            key={idx}
                            className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300"
                        >
                          <td className="rounded-md"></td>
                        </tr>
                    ))}
                </tbody>
              </table>
            </div>
        )}
      </div>
  );
}
