import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Sidebar(props) {
    const appName = process.env.REACT_APP_NAME;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = useLocation();
    const path =
        history.pathname.split("/")[history.pathname.split("/").length - 1];

    const [isExpanded, setIsExpanded] = useState(false); // Sidebar starts collapsed by default
    const [isMobile, setIsMobile] = useState(false);

    // Hook to detect mobile screen size via matchMedia
    useEffect(() => {
        // Check if `window` is available (to avoid issues in SSR or static builds)
        if (typeof window !== "undefined") {
            const mediaQuery = window.matchMedia("(max-width: 768px)");

            // Set initial state based on the current screen size
            setIsMobile(mediaQuery.matches);

            // Add event listener for media query changes
            const handleResize = (e) => {
                setIsMobile(e.matches);
            };

            mediaQuery.addEventListener("change", handleResize);

            // Cleanup on component unmount
            return () => {
                mediaQuery.removeEventListener("change", handleResize);
            };
        }
    }, []);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded); // Toggle the expanded state
    };

    return (
        <div className="flex h-screen">
            {/* Sidebar */}
            <motion.div
                animate={{
                    width: isExpanded ? "200px" : isMobile ? "60px" : "200px", // Sidebar width logic
                    transition: { ease: "easeInOut", duration: 0.5 },
                }}
                className={`bg-primary text-white h-full min-h-screen p-4 flex flex-col fixed z-50 
                    ${isMobile ? "absolute" : "static"} 
                    md:w-[200px] md:${isExpanded ? "absolute" : "static"}`}
            >
                {/* Sidebar Logo */}
                <motion.h1
                    animate={{
                        opacity: isExpanded ? "1" : isMobile ? "0" : "1",
                        transition: { ease: "easeInOut", duration: 0.5 },
                    }}
                >
                    <svg
                        onClick={() => navigate("/auth/dashboard/plays")}
                        className="logo-text cursor-pointer"
                        viewBox="0 0 480 110"
                    >
                        <text y="50">{appName}</text>
                    </svg>
                </motion.h1>

                {/* Hamburger Menu (Mobile Only) */}
                <div
                    className="flex justify-between items-center md:hidden cursor-pointer p-2"
                    onClick={toggleSidebar}
                >
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="bars"
                        className="svg-inline--fa fa-bars fa-lg"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path
                            fill="currentColor"
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32-14.3 32 32z"
                        ></path>
                    </svg>
                </div>

                {/* Sidebar Options */}
                <ul className="mt-8 flex flex-col">
                    {props.options && props.options.length > 0 ? (
                        props.options.map((option, index) => (
                            <div
                                key={index}
                                className={`${
                                    path.includes(option.url)
                                        ? "bg-white text-black font-bold"
                                        : ""
                                }`}
                                onClick={() =>
                                    option.action
                                        ? option.action(dispatch)
                                        : navigate(option.url)
                                }
                            >
                                <li className="flex justify-center md:justify-between items-center gap-1 cursor-pointer hover:bg-blue-800 hover:text-white p-2">
                                    <FontAwesomeIcon
                                        icon={option.icon}
                                        size="lg"
                                    />
                                    {/* Conditional rendering for text */}
                                    <p
                                        className={`${
                                            isExpanded
                                                ? "block"
                                                : "hidden"
                                        } md:block text-left text-xs sm:text-sm md:text-base`}
                                    >
                                        {option.title}
                                    </p>
                                </li>
                                <hr className="border-gray-400" />
                            </div>
                        ))
                    ) : (
                        <li className="text-center text-sm">
                            No options available
                        </li>
                    )}
                </ul>
            </motion.div>

            {/* Main Content Area */}
            <motion.div
                className={`flex-1 
                    ${isMobile ? "ml-2" : "ml-[150px]"} // Adjust for sidebar
                    transition-all duration-300`}
            >
                {props.children}
            </motion.div>
        </div>
    );
}
