import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import {saveQuote} from "../../../routes/api";
import { useNavigate } from "react-router-dom";

export default function AddQuote() {
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const initialValues = {
    play: "",
    channel_id: "",
    date: "",
    worst_odds: "",
    max_risk: "",
    max_win: "",
    smallest_bet_size: "",
    silent_fill: "",
  };

  const validationSchema = Yup.object({
    play: Yup.string()
        .required("Play is required"),
  });


  // Form submission handler
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log('form: ', values);

    saveQuote(values).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        // Clear the form
        resetForm();
        navigate("/auth/dashboard/quotes", { replace: true });
      }
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        // Validation errors
        const validationErrors = error.response.data.errors;
        for (const [field, messages] of Object.entries(validationErrors)) {
          // Show error messages for each field
          // messages.forEach(message => toast.error(`${field}: ${message}`));
          messages.forEach(message => toast.error(`${message}`));
        }
      } else {
        // Other errors
        toast.error('An error occurred. Please try again.');
      }

    });

    setSubmitting(false); // Reset isSubmitting to false after submission
  };


  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
            <Form>
              <h1 className="font-bold text-xl">Request Quote</h1>
              <div className="grid grid-cols-3 gap-4 mt-4">

                <div className="col-span-1">
                  <label htmlFor="name" className="form-label">Play</label>
                  <Field
                      type="text"
                      name="play"
                      id="play"
                      className="form-control"
                  />
                </div>

                <div className="col-span-1">
                  <label htmlFor="date" className="form-label required">Date</label>
                  <Field type="datetime-local" name="date" id="date" className="form-control" />
                  <ErrorMessage name="date" component="div" className="form-error" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="worst_odds" className="form-label required">Worst Odds</label>
                  <Field type="number" name="worst_odds" id="worst_odds" className="form-control" />
                  <ErrorMessage name="worst_odds" component="div" className="form-error" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="max_risk" className="form-label required">Max Risk</label>
                  <Field type="number" name="max_risk" id="max_risk" className="form-control" />
                  <ErrorMessage name="max_risk" component="div" className="form-error" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="max_win" className="form-label required">Max Win</label>
                  <Field type="number" name="max_win" id="max_win" className="form-control" />
                  <ErrorMessage name="max_win" component="div" className="form-error" />
                </div>

                <div className="col-span-1">
                  <label htmlFor="silent_fill" className="form-label">
                    <Field
                        type="checkbox"
                        name="silent_fill"
                        id="silent_fill"
                        className="form-checkbox"
                    />
                    Silent Fill
                  </label>
                  <ErrorMessage name="silent_fill" component="div" className="form-error" />
                </div>


              </div>


              <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Request
                </button>
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={() => navigate("/auth/dashboard/quotes")}
                >
                  Cancel
                </button>
              </div>
            </Form>

        )}

      </Formik>
  );
}
